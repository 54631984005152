<template>
  <div class="container">
    <div class="grid justify-content-center">
      <div class="col md:col-5">
       <div class="card nice-shadow-6">
          <div class="my-3">
            <div class="text-2xl font-bold">Password Reset</div>
          </div>
          <form ref="observer" tag="form" @submit.prevent="resetPassword()">
            <div class="mb-3 w-full">
              <Password
                :feedback="true"
                toggleMask
                class="w-full"
                inputClass="w-full"
                v-model="formData.password"
                placeholder="New Password"
              />
            </div>
            <div class="mb-3 w-full">
              <Password
                toggleMask
                class="w-full"
                inputClass="w-full"
                v-model="formData.confirm_password"
                placeholder="Confirm new password"
              />
            </div>
            <div class="text-center">
              <Button
                type="submit"
                class="w-full py-3"
                :loading="saving"
                label="Change Password"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { PageMixin } from "../../mixins/page.js";
import useVuelidate from "@vuelidate/core";
import { required, sameAs } from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  props: {},
  mixins: [PageMixin],
  data: function () {
    return {
      formData: {
        password: "",
        confirm_password: "",
        token: "",
        email: "",
      },
      saving: false,
    };
  },
  validations() {
    let formData = {
      password: { required },
      confirm_password: {
        required,
        sameAsPassword: sameAs(this.formData.password),
      },
    };
    return { formData };
  },
  methods: {
    async resetPassword() {
      const isFormValid = !this.v$.$invalid;
      if (!isFormValid) {
        this.flashMsg("Please complete the form", "Form is invalid", "error");
        return;
      }
      this.saving = true;
      this.formData.token = this.$route.query.token || null;
      this.formData.email = this.$route.query.email || null;
      this.$api.post("auth/resetpassword", this.formData).then(
        (response) => {
          this.saving = false;
          this.$router.push("/index/resetpassword_completed");
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
  },
};
</script>
